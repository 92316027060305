import { useRecoilState } from 'recoil';
import $popupAlert from '../recoil/atom/common/popup-alert';

function usePopupAlert(domain: string) {
    const [popupAlert, setPopupAlert] = useRecoilState($popupAlert(domain));

    const { alert, error, notice, confirm, callback, cancelCallback } = popupAlert;

    function alertRemove() {
        setPopupAlert((prev) => ({
            ...prev,
            alert: '',
        }));
    }

    function errorRemove() {
        setPopupAlert((prev) => ({
            ...prev,
            error: '',
        }));
    }

    function noticeRemove() {
        if (callback) {
            callback();
        }

        setPopupAlert((prev) => ({
            ...prev,
            notice: '',
        }));
    }

    function confirmRemove() {
        setPopupAlert((prev) => ({
            ...prev,
            confirm: '',
        }));
    }

    function setAlertMsg(
        key: 'alert' | 'error' | 'notice' | 'confirm',
        message: string,
        callback?: () => void,
        cancelCallback?: () => void
    ) {
        setPopupAlert((prev) => ({
            ...prev,
            [key]: message,
            callback,
            cancelCallback,
        }));
    }

    return {
        alert,
        error,
        notice,
        confirm,
        callback,
        cancelCallback,
        alertRemove,
        errorRemove,
        noticeRemove,
        confirmRemove,
        setAlertMsg,
    };
}

export default usePopupAlert;
