/* eslint-disable no-nested-ternary */
import React, { lazy, Suspense, useCallback, useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from "recoil";

import { PATH } from "./shared/constant";

import "./style/_init.scss";
import "./style/_mixin.scss";
import "./style/common.scss";
import "./style/style.scss";

import $media from "./recoil/atom/mediaAtom";
import useComponentDidMount from "./hook/useComponentDidMount";
import useComponentWillUnmount from "./hook/useComponentWillUnmount";
import $isLogin from "./recoil/atom/login/is-login";
import Redirect from "./pages/redirect";
import Footer from "./components/layout/footer";
import $isUserLogin from "./recoil/atom/login/is-user-login";
import useAuth from "./hook/use-auth";
import Scenario from "./pages/scenario";
import UserRouter from "./router/userRouter";
import AdminRouter from "./router/adminRouter";
import RootRouter from "./router/rootRouter";
import NonUserRouter from "./router/non-userRouter";
import dynamincManifestHandler from "./router/manifest";

// 링크 & 컴포넌트 모음
const Index = lazy(() => import("./pages/index"));
const Index2 = lazy(() => import("./pages/index2"));
const Components = lazy(() => import("./pages/components"));

// 페이지
const Login = lazy(() => import("./pages/login/index"));
const LoginType2 = lazy(() => import("./pages/login/login"));
const FindID = lazy(() => import("./pages/login/find_id"));
const FindPW = lazy(() => import("./pages/login/find_pw"));

// 회원가입
const Join = lazy(() => import("./pages/join/index"));
const NiceSuccess = lazy(() => import("./pages/join/nice_success"));
const NiceError = lazy(() => import("./pages/join/nice_error"));

// 대시보드
const Dashboard = lazy(() => import("./pages/dashboard/index"));

// 인프라
const Infra = lazy(() => import("./pages/infra/index"));
const StationList = lazy(() => import("./pages/infra/station_list"));
const StationListDetail = lazy(
    () => import("./pages/infra/station_list_detail")
);
const StationRegistration = lazy(
    () => import("./pages/infra/station_registration")
);
const StationModify = lazy(() => import("./pages/infra/station_modify"));
const ChargerList = lazy(() => import("./pages/infra/charger_list"));

// 빌링
const Billing = lazy(() => import("./pages/billing"));
const PaymentInfo = lazy(() => import("./pages/billing/payment/payment_info"));
const VerificationPaymentInfo = lazy(
    () => import("./pages/billing/payment/verification_payment_info")
);
const PaymentCancelInfo = lazy(
    () => import("./pages/billing/payment/payment_cancel_info")
);

// 정산 added by basagee@gmail.com
const AccountingProcessing = lazy(
    () => import("./pages/billing/accounting/processing")
);
const AccountingReports = lazy(
    () => import("./pages/billing/accounting/reports")
);
const AccountingSettings = lazy(
    () => import("./pages/billing/accounting/settings")
);

const Point = lazy(() => import("./pages/billing/point"));
const Rate = lazy(() => import("./pages/billing/rate"));

// 회원
// 회원 - 일반회원
const Member = lazy(() => import("./pages/member"));
const MemberList = lazy(
    () => import("./pages/member/general_member/member_list")
);
const MemberInfo = lazy(
    () => import("./pages/member/general_member/member_info")
);
const MemberReg = lazy(
    () => import("./pages/member/general_member/member_reg")
);
const MemberModify = lazy(
    () => import("./pages/member/general_member/member_modify")
);
const AccessInfo = lazy(
    () => import("./pages/member/general_member/access_info")
);
const CardIssuer = lazy(
    () => import("./pages/member/general_member/card_issuer")
);
const Terms = lazy(() => import("./pages/member/general_member/terms"));
const TermsReg = lazy(() => import("./pages/member/general_member/terms_reg"));
const TermsModify = lazy(
    () => import("./pages/member/general_member/terms_modify")
);
// 회원 - 사업자
const BusinessList = lazy(
    () => import("./pages/member/business/business_list")
);
const BusinessInfo = lazy(
    () => import("./pages/member/business/business_info")
);
const BusinessReg = lazy(() => import("./pages/member/business/business_reg"));
const BusinessModify = lazy(
    () => import("./pages/member/business/business_modify")
);
const BusinessBilling = lazy(
    () => import("./pages/member/business/business_billing")
);

const ManagerList = lazy(() => import("./pages/member/manager/manager_list"));
const ManagerAccessInfo = lazy(
    () => import("./pages/member/manager/manager_access_info")
);
const ManagerLog = lazy(() => import("./pages/member/manager/manager_log"));
const MyInfo = lazy(() => import("./pages/member/my_info"));
// 운용
const Operation = lazy(() => import("./pages/operation"));
const Power = lazy(() => import("./pages/operation/power"));
const Communication = lazy(() => import("./pages/operation/communication"));

const FailureInfo = lazy(
    () => import("./pages/operation/failure/failure_info")
);

const CheckInfo = lazy(() => import("./pages/operation/failure/check_info"));

const FailureDeclare = lazy(
    () => import("./pages/operation/failure/failure_declare")
);

const FailureDeclareModify = lazy(
    () => import("./pages/operation/failure/failure_declare_modify")
);

const WorkReport = lazy(() => import("./pages/operation/failure/work_report"));
const WorkReportModify = lazy(
    () => import("./pages/operation/failure/work_report_modify")
);

const Firmware = lazy(() => import("./pages/operation/firmware"));

const SetPermission = lazy(
    () => import("./pages/operation/general/set_permission")
);

const Report = lazy(() => import("./pages/operation/general/report"));
const Maker = lazy(() => import("./pages/operation/general/maker"));
const CarModel = lazy(() => import("./pages/operation/general/car_model"));
const ChargerModel = lazy(
    () => import("./pages/operation/general/charger_model")
);

const SetMemberCard = lazy(
    () => import("./pages/operation/general/set_member_card")
);

const ChargeUnitPrice = lazy(
    () => import("./pages/operation/general/charge_unit_price")
);

const MemberInpuiry = lazy(
    () => import("./pages/operation/service_center/member_inquiry")
);

const ConsultantList = lazy(
    () => import("./pages/operation/service_center/consultant_list")
);
const ConsultationList = lazy(
    () => import("./pages/operation/service_center/consultation_list")
);

const ConsultationReg = lazy(
    () => import("./pages/operation/service_center/consultation_reg")
);

// 일반회원가입 - 약관동의
const UseTerms = lazy(() => import("./pages/use_terms/index"));

// const Scenario = lazy(() => import('./pages/scenario'))

const Mypage = lazy(() => import("./pages/mypage"));
const MypageEdit = lazy(() => import("./pages/mypage/my_edit"));
const ChargingCardChange = lazy(
    () => import("./pages/mypage/charging_card_change")
);
const PaymentCardChange = lazy(
    () => import("./pages/mypage/payment_card_change")
);
const ChargingCardReg = lazy(() => import("./pages/mypage/charging_card_reg"));

function Router() {
    const isUserLogin = useRecoilValue($isUserLogin);

    const isLogin = useRecoilValue($isLogin);

    const { isLoad } = useAuth();

    const setMedia = useSetRecoilState($media);

    const onResize = useCallback(() => {
        setMedia(window.innerWidth <= 768 ? "M" : "P");
    }, []);

    useComponentDidMount(() => {
        window.addEventListener("resize", onResize);

        console.warn = () => {
            // 경고문 무시
        };
    });

    useComponentWillUnmount(() => {
        window.removeEventListener("resize", onResize);
    });

    useEffect(() => {
        dynamincManifestHandler();
    });

    return (
        <Suspense fallback="loading...">
            <BrowserRouter>
                <Routes>
                    <Route path={PATH.INDEX2} element={<Index2 />} />
                    <Route path={PATH.COMPONENTS} element={<Components />} />
                    <Route path={PATH.NICE_SUCCESS} element={<NiceSuccess />} />
                    <Route path={PATH.NICE_ERROR} element={<NiceError />} />

                    {/* 공통 */}
                    <Route element={<RootRouter />}>
                        <Route path="/" element={<LoginType2 />} />
                    </Route>

                    {/* 일반회원 진입페이지(비로그인) */}
                    <Route element={<NonUserRouter />}>
                        <Route path={PATH.LOGIN} element={<Login />} />
                        <Route
                            path={PATH.LOGIN_TYPE2}
                            element={<LoginType2 />}
                        />
                        <Route path={PATH.USER_TERMS} element={<UseTerms />} />
                        <Route path={PATH.JOIN} element={<Join />} />
                        <Route path={PATH.FIND_ID} element={<FindID />} />
                        <Route path={PATH.FIND_PW} element={<FindPW />} />
                    </Route>

                    {/* 일반회원 진입페이지 */}
                    <Route element={<UserRouter />}>
                        <Route path={PATH.MYPAGE} element={<Mypage />} />
                        <Route
                            path={PATH.MYPAGE_EDIT}
                            element={<MypageEdit />}
                        />
                        <Route
                            path={PATH.CHARGING_CARD_CHANGE}
                            element={<ChargingCardChange />}
                        />
                        <Route
                            path={PATH.PAYMENT_CARD_CHANGE}
                            element={<PaymentCardChange />}
                        />
                        <Route
                            path={PATH.CHARGING_CARD_REG}
                            element={<ChargingCardReg />}
                        />
                    </Route>

                    {/* 관리자 진입페이지 */}
                    <Route element={<AdminRouter />}>
                        <Route path={PATH.DASHBOARD} element={<Dashboard />} />
                        <Route path={PATH.INFRA} element={<Infra />} />
                        <Route
                            path={PATH.STATION_LIST}
                            element={<StationList />}
                        />
                        <Route
                            path={PATH.STATION_LIST_DETAIL}
                            element={<StationListDetail />}
                        />
                        <Route
                            path={PATH.STATION_REGISTRATION}
                            element={<StationRegistration />}
                        />
                        <Route
                            path={PATH.STATION_MODIFY}
                            element={<StationModify />}
                        />
                        <Route
                            path={PATH.CHARGERLIST}
                            element={<ChargerList />}
                        />
                        <Route path={PATH.BILLING} element={<Billing />} />
                        <Route
                            path={PATH.PAYMENT_INFO}
                            element={<PaymentInfo />}
                        />
                        <Route
                            path={PATH.VERIFICATION_PAYMENT}
                            element={<VerificationPaymentInfo />}
                        />
                        <Route
                            path={PATH.PAYMENT_CANCEL_INFO}
                            element={<PaymentCancelInfo />}
                        />
                        {/* basagee@gmail.com */}
                        <Route
                            path={PATH.ACCOUNTING_PROCESSING}
                            element={<AccountingProcessing />}
                        />
                        <Route
                            path={PATH.ACCOUNTING_REPORTS}
                            element={<AccountingReports />}
                        />
                        <Route
                            path={PATH.ACCOUNTING_SETTINGS}
                            element={<AccountingSettings />}
                        />
                        <Route path={PATH.POINT} element={<Point />} />
                        <Route path={PATH.RATE} element={<Rate />} />
                        <Route path={PATH.MEMBER} element={<Member />} />
                        <Route
                            path={PATH.MEMBER_LIST}
                            element={<MemberList />}
                        />
                        <Route
                            path={PATH.MEMBER_INFO}
                            element={<MemberInfo />}
                        />
                        <Route path={PATH.MEMBER_REG} element={<MemberReg />} />
                        <Route
                            path={PATH.MEMBER_MODIFY}
                            element={<MemberModify />}
                        />
                        <Route
                            path={PATH.ACCESS_INFO}
                            element={<AccessInfo />}
                        />
                        <Route
                            path={PATH.CARD_ISSUER}
                            element={<CardIssuer />}
                        />
                        <Route path={PATH.TERMS} element={<Terms />} />
                        <Route path={PATH.TERMS_REG} element={<TermsReg />} />
                        <Route
                            path={PATH.TERMS_MODIFY}
                            element={<TermsModify />}
                        />
                        <Route
                            path={PATH.BUSINESS_LIST}
                            element={<BusinessList />}
                        />
                        <Route
                            path={PATH.BUSINESS_INFO}
                            element={<BusinessInfo />}
                        />
                        <Route
                            path={PATH.BUSINESS_REG}
                            element={<BusinessReg />}
                        />
                        <Route
                            path={PATH.BUSINESS_MODIFY}
                            element={<BusinessModify />}
                        />
                        <Route
                            path={PATH.BUSINESS_BILLING}
                            element={<BusinessBilling />}
                        />
                        <Route
                            path={PATH.MANAGER_LIST}
                            element={<ManagerList />}
                        />
                        <Route
                            path={PATH.MANAGER_ACCESS_INFO}
                            element={<ManagerAccessInfo />}
                        />
                        <Route
                            path={PATH.MANAGER_LOG}
                            element={<ManagerLog />}
                        />
                        <Route path={PATH.MY_INFO} element={<MyInfo />} />
                        <Route path={PATH.OPERATION} element={<Operation />} />
                        <Route path={PATH.POWER} element={<Power />} />
                        <Route
                            path={PATH.COMMUNICATION}
                            element={<Communication />}
                        />
                        <Route
                            path={PATH.FAILURE_INFO}
                            element={<FailureInfo />}
                        />
                        <Route path={PATH.CHECK_INFO} element={<CheckInfo />} />
                        <Route
                            path={PATH.FAILURE_DECLARE}
                            element={<FailureDeclare />}
                        />
                        <Route
                            path={PATH.FAILURE_DECLARE_MODIFY}
                            element={<FailureDeclareModify />}
                        />
                        <Route
                            path={PATH.WORK_REPORT}
                            element={<WorkReport />}
                        />
                        <Route
                            path={PATH.WORK_REPORT_MODIFY}
                            element={<WorkReportModify />}
                        />
                        <Route path={PATH.FIRMWARE} element={<Firmware />} />
                        <Route
                            path={PATH.SET_PERMISSION}
                            element={<SetPermission />}
                        />
                        <Route path={PATH.REPORT} element={<Report />} />
                        <Route path={PATH.MAKER} element={<Maker />} />
                        <Route path={PATH.CAR_MODEL} element={<CarModel />} />
                        <Route
                            path={PATH.CHARGER_MODEL}
                            element={<ChargerModel />}
                        />
                        <Route
                            path={PATH.SET_MEMBER_CARD}
                            element={<SetMemberCard />}
                        />
                        <Route
                            path={PATH.CHARGE_UNIT_PRICE}
                            element={<ChargeUnitPrice />}
                        />
                        <Route
                            path={PATH.MEMBER_INQUIRY}
                            element={<MemberInpuiry />}
                        />
                        <Route
                            path={PATH.CONSULTANT_LIST}
                            element={<ConsultantList />}
                        />
                        <Route
                            path={PATH.CONSULTATION_LIST}
                            element={<ConsultationList />}
                        />
                        <Route
                            path={PATH.CONSULTATION_REG}
                            element={<ConsultationReg />}
                        />
                    </Route>

                    {/* OCPP 테스트 */}
                    <Route path={PATH.OCPP_SCENARIO} element={<Scenario />} />

                    {/* 예외처리 */}
                    {isLogin ? (
                        <Route
                            path="*"
                            element={<Redirect url={PATH.DASHBOARD} />}
                        />
                    ) : isUserLogin ? (
                        <Route
                            path="*"
                            element={<Redirect url={PATH.MYPAGE} />}
                        />
                    ) : (
                        <Route
                            path="*"
                            element={<Redirect url={PATH.LOGIN_TYPE2} />}
                        />
                    )}
                </Routes>
                <Footer />
            </BrowserRouter>
        </Suspense>
    );
}

export default Router;
