const manifest = {
    short_name: 'EV',
    name: 'EV충전인프라 운영시스템',
    icons: [
        {
            src: 'favicon.ico',
            sizes: '64x64 32x32 24x24 16x16',
            type: 'image/x-icon',
        },
        {
            src: 'logo192.png',
            type: 'image/png',
            sizes: '192x192',
        },
        {
            src: 'logo512.png',
            type: 'image/png',
            sizes: '512x512',
        },
    ],
    display: 'standalone',
    theme_color: '#000000',
    background_color: '#ffffff',
};

const dynamincManifestHandler = () => {
    // const isMobile =
    //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   )

    const manifest = document.querySelector('#manifest_id');
    // const appTitle = document.querySelector('#ev_app_title_id')

    if (manifest) {
        // let userType = ''
        // if (window.location.pathname.includes('csms')) userType = ' - 운영자'
        // else if (window.location.pathname.includes('user')) userType = ' - 사용자'

        // if (isMobile) appTitle.innerHTML = `EV충전인프라 운영시스템${userType}`
        // else appTitle.innerHTML = `EV충전인프라 운영시스템`

        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);

        manifest.setAttribute('href', manifestURL);

        const link = document.createElement('link');
        link.rel = 'manifest';
        link.setAttribute('href', `data:application/json;charset=8${stringManifest}`);
    }
};

export default dynamincManifestHandler;
