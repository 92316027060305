import { useNavigate } from 'react-router-dom';
import Header from '../components/redirect/header';
import notFond from '../static/img/icons/not-found.png';

import '../style/pages/redirect.scss';

interface Props {
    url: string;
}

function Redirect({ url }: Props) {
    const navigate = useNavigate();

    function goBack() {
        navigate(-1);
    }

    function goHome() {
        navigate(url, { replace: true });
    }

    return (
        <div id="redirect">
            <Header />
            <main>
                <img src={notFond} alt="404 아이콘" />
                <h2>404</h2>
                <span>(404 Not Found)</span>
                <p>
                    페이지가 존재하지 않거나, 사용할 수 없는 페이지 입니다.
                    <br />
                    입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
                </p>
                <div>
                    <button type="button" onClick={goBack}>
                        이전 페이지로 가기
                    </button>
                    <button type="button" onClick={goHome}>
                        홈으로 가기
                    </button>
                </div>
            </main>
        </div>
    );
}

export default Redirect;
