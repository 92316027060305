import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PATH } from '../../shared/constant';
import '../../style/components/layout/footer.scss';
import If from './if';

function Footer() {
    const location = useLocation();

    useEffect(() => {
        document.body.style.overflow = '';
    }, [location]);

    return (
        <If
            predicate={location.pathname !== PATH.NICE_SUCCESS || location.pathname !== PATH.NICE_SUCCESS}
            right={
                <footer>
                    <div className="top">
                        <span>주식회사 엔비플러스</span>
                        <div className="bar" />
                        <span>대표이사 임은영</span>
                        <div className="bar" />
                        <span>사업자등록번호 329-88-02381</span>
                    </div>
                    <div className="middle">
                        <div className="row">
                            {/* 본사 : */}
                            <span>전라남도 나주시 왕곡면 혁신산단로 160</span>
                            <div className="bar" />
                            <span>TEL : 061-336-3263</span>
                        </div>
                        {/* <div className='row'>
              <span>
                연구소 : 서울 영등포구 버드나루로 12가길 51 에이동 2층
              </span>
              <div className='bar' />
              <span>TEL : 02-451-8721</span>
            </div> */}
                    </div>

                    <p>Copyright©2012- 2023 NBplus All Rights Reserved.</p>
                </footer>
            }
        />
    );
}

export default Footer;
