import { useQuery } from 'react-query';
import getQuery from '../../../get-query';
import ReactQueryOptionsType from '../../../model/react-query-options-type';

export type LevelNumbering = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export type LevelProperty = `level${LevelNumbering}`;

export type LevelModel = {
    [key in LevelProperty]: 0 | 1;
};

export interface GetSetPermissionListItemModel extends LevelModel {
    idx: number;
    menuName1: string;
    menuName2: string;
    menuName3: string;
    name: string;
}

function useGetSetPermissionList(params: PageableRequestModel, options?: ReactQueryOptionsType) {
    const getSetPermissionList = useQuery<ListResponseModel<GetSetPermissionListItemModel>>(
        ['@operation/general/set-permission/get-set-permission-list', params],
        () => {
            return getQuery({
                url: '/operation/auth/list',
                sendAccessToken: true,
                params,
            });
        },
        options
    );

    return getSetPermissionList;
}

export default useGetSetPermissionList;
