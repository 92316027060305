import { atom } from 'recoil';

interface PopupAlertModel {
    alert: string;
    error: string;
    notice: string;
    confirm: string;
    callback?: () => void;
    cancelCallback?: () => void;
}

const defaultValue: PopupAlertModel = {
    alert: '',
    error: '',
    notice: '',
    confirm: '',
    callback: undefined,
    cancelCallback: undefined,
};

const $popupAlert = (domain: string) => {
    return atom<PopupAlertModel>({
        key: `@${domain}/popup-alert`,
        default: defaultValue,
    });
};

export default $popupAlert;
