import React from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import $isLogin from '../recoil/atom/login/is-login';
import $isUserLogin from '../recoil/atom/login/is-user-login';
import { PATH } from '../shared/constant';

function NonUserRouter() {
    const isUserLogin = useRecoilValue($isUserLogin);
    const isLogin = useRecoilValue($isLogin);

    if (isLogin) {
        window.location.replace(PATH.DASHBOARD);
    } else if (isUserLogin) {
        window.location.replace(PATH.MYPAGE);
    }

    return <Outlet />;
}

export default NonUserRouter;
