import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    atom,
    atomFamily,
    RecoilState,
    RecoilValue,
    RecoilValueReadOnly,
    useRecoilState,
    useRecoilValue,
    useRecoilValueLoadable,
    useSetRecoilState,
} from 'recoil';

import './recoil-indicator.scss';

import icon from './flow.png';
import reset from './reset.png';

const focusAtom = atom({
    key: '@dev/recoil-indicator',
    default: '',
});

const selectAtom = atom<'atom' | 'selector'>({
    key: '@dev/recoil-indicator/select',
    default: 'atom',
});

const openListAtom = atom<Array<number>>({
    key: '@dev/recoil-indicator/open_list',
    default: [],
});

const historyAtom = atomFamily<Array<HistoryModel>, string>({
    key: '@dev/recoil-indicator/history',
    default: [],
});

interface HistoryModel {
    status: 'hasValue' | 'loading' | 'hasError';
    time: string;
    contents: any;
}

interface ListItemModel {
    target: RecoilValue<unknown> | RecoilState<unknown>;
    name: string;
    setHistory: React.Dispatch<React.SetStateAction<Array<HistoryModel>>>;
}

interface ArrayItemModel {
    item: Array<any>;
    parent: number;
}

interface ItemModel {
    item: any;
    id: number;
}

declare global {
    interface Element {
        click: () => void;
    }
}

const paddingLeft = 15;

const Component = {
    ListItem: (props: ListItemModel) => {
        const [focus, setFocus] = useRecoilState(focusAtom);

        const [history, setHistory] = useRecoilState(historyAtom(props.name));

        const loadable = useRecoilValueLoadable(props.target);

        const reset = () => {
            setHistory(history.length ? [history[history.length - 1]] : []);
        };

        useEffect(() => {
            if (
                history.length &&
                JSON.stringify(history[history.length - 1].contents) === JSON.stringify(loadable.contents)
            ) {
                return;
            }

            const d = new Date();

            const time = `${d.getFullYear()}-${
                d.getMonth() + 1
            }-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.${d.getSeconds()}`;

            setHistory((_) =>
                _.concat({
                    time,
                    status: loadable.state,
                    contents: loadable.contents,
                })
            );
        }, [loadable]);

        useEffect(() => {
            if (focus === props.name) {
                props.setHistory(history);
            }
        }, [focus, history, props.name]);

        return (
            <>
                <button type="button" onClick={() => setFocus(props.name)} className={focus === props.name ? 'f' : ''}>
                    {props.name}
                </button>
                <button
                    type="button"
                    id={focus === props.name ? 'focused_item' : ''}
                    onClick={reset}
                    style={{ display: 'none' }}
                >
                    {' '}
                </button>
            </>
        );
    },
    ArrayItem: (props: ArrayItemModel) => {
        const openList = useRecoilValue(openListAtom);

        if (props.item === null || typeof props.item !== 'object') return <> </>;

        return (
            <>
                {Object.keys(props.item).map((key: any, index) => {
                    const i = props.item[key];

                    const id = parseInt(`${props.parent}${index}`, 10);

                    return (
                        <li key={key}>
                            <div>
                                <span className="key">{key} :</span>
                                <Component.Item item={i} id={id} />
                            </div>
                            {openList.includes(id) && (
                                <ul className="detail-ul mt-zero" style={{ paddingLeft }}>
                                    <Component.ArrayItem item={i} parent={id} />
                                </ul>
                            )}
                        </li>
                    );
                })}
            </>
        );
    },
    Item: (props: ItemModel) => {
        const ref = useRef<HTMLParagraphElement | null>(null);

        const { id, item } = props;

        const [maxWidth, setMaxWidth] = useState<number | string>(0);

        const setOpenList = useSetRecoilState(openListAtom);

        const typeClass = util.getTypeClass(item);

        const onCopy = (target: any) => {
            const element = document.createElement('textarea');
            element.value = target;
            document.body.appendChild(element);

            element.select();
            document.execCommand('copy');
            document.body.removeChild(element);
        };

        const onClickObj = (e: number, typeClass: string) => {
            if (!['arr', 'obj'].includes(typeClass)) {
                onCopy(util.getText(item));
                return;
            }

            setOpenList((_) => {
                return _.includes(e) ? _.filter((i) => i !== e) : _.concat(e);
            });
        };

        useEffect(() => {
            if (ref.current) {
                const prev = ref.current!.previousElementSibling;

                if (prev) {
                    const style = window.getComputedStyle(prev);

                    setMaxWidth(`calc(calc(100% - ${prev.clientWidth}px) - ${style.marginRight})`);
                }
            }
        }, []);

        return (
            <p onClick={() => onClickObj(id, typeClass)} className={`ca ${typeClass}`} ref={ref} style={{ maxWidth }}>
                {typeof item === 'function' ? (
                    item.toString()
                ) : item === undefined ? (
                    'undefined'
                ) : item === null ? (
                    'null'
                ) : Array.isArray(item) ? (
                    <>
                        {'[ '}
                        {item.map((value, index) => {
                            return (
                                <>
                                    <span className={util.getTypeClass(value)}>{util.getText(value)}</span>
                                    {index === item.length - 1 ? '' : ' , '}
                                </>
                            );
                        })}
                        {' ]'}
                    </>
                ) : typeof item === 'object' ? (
                    <>
                        {'{ '}
                        {Object.keys(item).map((key, index) => {
                            const value = item[key];

                            return (
                                <Fragment key={key}>
                                    <span className="key">{key}</span>
                                    {' : '}
                                    <span className={util.getTypeClass(value)}>{util.getText(value)}</span>
                                    {index === Object.keys(item).length - 1 ? '' : ' , '}
                                </Fragment>
                            );
                        })}
                        {' }'}
                    </>
                ) : (
                    JSON.stringify(item)
                )}
            </p>
        );
    },
};

const util = {
    getClickAbleClass: (i: any) => {
        return i !== null && typeof i === 'object' ? 'ca' : '';
    },
    getTypeClass: (i: any) => {
        return typeof i === 'string'
            ? 'str'
            : typeof i === 'number'
            ? 'num'
            : typeof i === 'bigint'
            ? 'num'
            : typeof i === 'boolean'
            ? 'bool'
            : typeof i === 'function'
            ? 'func'
            : i === null
            ? 'null'
            : i === undefined
            ? 'und'
            : Array.isArray(i)
            ? 'arr'
            : 'obj';
    },
    getText: (i: any) => {
        return typeof i === 'function'
            ? 'fn'
            : i === undefined
            ? 'undefined'
            : i === null
            ? 'null'
            : Array.isArray(i)
            ? i.length === 0
                ? '[]'
                : '[...]'
            : typeof i === 'object'
            ? Object.keys(i).length === 0
                ? '{}'
                : '{...}'
            : typeof i === 'number'
            ? util.isFloat(i)
                ? i
                : util.comma(i)
            : JSON.stringify(i);
    },
    comma: (_x: string | number): string => {
        try {
            return _x
                .toString()
                .replace(/,/gi, '')
                .replace(/[^0-9]/gi, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } catch {
            return '';
        }
    },
    isFloat: (e: number) => {
        return e.toString().includes('.');
    },
};

interface Props {
    atoms: {
        [key: string]: RecoilState<unknown>;
    };
    selectors: { [key: string]: RecoilValueReadOnly<unknown> };
}

function RecoilIndicator(props: Props) {
    const { atoms, selectors } = props;

    const [show, setShow] = useState(false);

    const [theme, setTheme] = useState(localStorage.getItem('__recoil_dev_tools__$theme') || 'dark');

    const [select, setSelect] = useRecoilState(selectAtom);

    const [history, setHistory] = useState<Array<HistoryModel>>([]);

    const openList = useRecoilValue(openListAtom);

    const onClickReset = () => {
        const target = document.querySelector('#focused_item');
        if (target) {
            target.click();
        }
    };

    useEffect(() => {
        localStorage.setItem('__recoil_dev_tools__$theme', theme);
    }, [theme]);

    return (
        <div id="__recoil_dev_tools__" className={theme}>
            <button type="button" onClick={() => setShow(!show)}>
                <img src={icon} alt="flow icon" />
            </button>
            <div className={show ? 's' : ''}>
                <nav>
                    <button type="button" onClick={() => setSelect('atom')} className={select === 'atom' ? 'a' : ''}>
                        Atom
                    </button>
                    <button
                        type="button"
                        onClick={() => setSelect('selector')}
                        className={select === 'selector' ? 'a' : ''}
                    >
                        Selector
                    </button>

                    <div className="toggle" onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}>
                        <div />
                    </div>

                    <div className={`reset ${history.length <= 1 ? 'h' : ''}`} onClick={onClickReset}>
                        <img src={reset} alt="초기화 아이콘" />
                    </div>
                </nav>
                <article>
                    <aside>
                        {Object.keys(select === 'atom' ? atoms : selectors).map((key) => {
                            const item = (select === 'atom' ? atoms : selectors)[key];

                            return <Component.ListItem target={item} key={key} name={key} setHistory={setHistory} />;
                        })}
                    </aside>
                    <section>
                        <ul>
                            {[...history].reverse().map((i, index) => {
                                const { length } = history;

                                const id = length - index + 0;

                                return (
                                    <li key={id}>
                                        <div>
                                            <h3>@{length - index}</h3>
                                            <span>{i.time}</span>
                                            <div
                                                className={
                                                    i.status === 'hasError'
                                                        ? 'he'
                                                        : i.status === 'hasValue'
                                                        ? 'hv'
                                                        : 'l'
                                                }
                                            />
                                        </div>
                                        <div>
                                            <div className="v">V</div>
                                            <Component.Item id={id} item={i.contents} />
                                        </div>
                                        {openList.includes(id) && (
                                            <ul className="detail-ul" style={{ paddingLeft: 35 }}>
                                                <Component.ArrayItem item={i.contents} parent={id} />
                                            </ul>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </section>
                </article>
            </div>
        </div>
    );
}

export default RecoilIndicator;
