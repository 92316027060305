/* eslint-disable react/jsx-no-useless-fragment */
interface Props extends DescriptionProps {
    predicate: boolean;
    right?: React.ReactNode;
    wrong?: React.ReactNode;
}

function If({ predicate, right, wrong }: Props) {
    if (predicate) return right ? <>{right}</> : <></>;

    return wrong ? <>{wrong}</> : <></>;
}

export default If;
