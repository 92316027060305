import { useEffect, useRef, useState } from 'react';
import '../../style/components/select/select.scss';
import { useRecoilValue } from 'recoil';
import arrow from '../../static/img/icons/menu_arrow.png';
import $media from '../../recoil/atom/mediaAtom';

export interface optionsData {
    id?: string;
    optionValue: string;
    pointRate?: number;
    search_key?: number;
}

export interface SearchSelectProps {
    placeholder?: string;
    readOnly?: boolean;
    isRise?: boolean; // options box 위로 나오게
    options: optionsData[];
    value?: string;
    onChange?: (e: optionsData) => void;
    onChangeScroll?: () => void;
    onChangeSearchKeyword?: (keyword: string) => void; // 키워드 검색기능을 별도로 이용할 경우
}

function Select(props: SearchSelectProps) {
    const { placeholder, readOnly, isRise, options, value, onChange, onChangeScroll, onChangeSearchKeyword } = props;

    const media = useRecoilValue($media);

    const selectedRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpne] = useState(false);

    // select 영역 밖 클릭시  options 닫힘
    function onCheckVaildArea(e: MouseEvent) {
        if (selectedRef.current && !selectedRef.current.contains(e.target as Node)) {
            setIsOpne(false);
        }
    }

    // options 값 선택 후 event
    function onSelectedOption(item: optionsData) {
        setIsOpne(false);
        if (onChange) {
            onChange(item);
        }
    }

    // 모바일일때 옵션창 열릴시 스크롤 방지
    function scrollPause() {
        if (media === 'M') {
            if (isOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    }

    useEffect(() => {
        window.addEventListener('click', onCheckVaildArea);
        return () => {
            window.addEventListener('click', onCheckVaildArea);
        };
    }, [selectedRef]);

    useEffect(() => {
        scrollPause();
    }, [isOpen]);

    return (
        <div className="select_box">
            {media === 'M' && isOpen && <div className="search_select_dim" />}
            <div className="search_select" ref={selectedRef}>
                <div
                    className={`selected ${isOpen ? 'focus' : ''} ${
                        value !== placeholder && value !== '선택' ? 'isSelected' : ''
                    }`}
                    onClick={() => setIsOpne(!isOpen)}
                >
                    {value || (placeholder ?? '선택')}
                    <img src={arrow} alt="arrow" />
                </div>
                {isOpen && (
                    <div className={`options ${isRise ? 'rise' : ''}`}>
                        <div className="options_list">
                            {options.map((item) => {
                                return (
                                    <div key={item.optionValue} onClick={() => onSelectedOption(item)}>
                                        {item.optionValue}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Select;
