import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import $userCompanyIndex from '../recoil/atom/common/user-company-index';
import Get from '../service/get';

function useGetCompanyIndex(myProfile: boolean) {
    const [userCompanyIndex, setUserCompanyIndex] = useRecoilState($userCompanyIndex);

    const freeze = useRef<boolean>(false);

    const companyIndexGetReq = useRef<Get<unknown>>(
        new Get('/auth/company', {
            success: companyIndexGetRequestSuccessCallback,
            error: companyIndexGetRequestErrorCallback,
            finally: companyIndexGetRequestFinallyCallback,
        }).set({
            loadingBlock: true,
            loginCheck: true,
        })
    );

    function companyIndexGetRequestSuccessCallback({ companyIdx }: { companyIdx: number }) {
        setUserCompanyIndex(companyIdx);
    }

    function companyIndexGetRequestErrorCallback() {
        setUserCompanyIndex(0);
    }

    function companyIndexGetRequestFinallyCallback() {
        freeze.current = true;
        setTimeout(() => {
            freeze.current = false;
        }, 60000);
    }

    function getCompanyIdx() {
        companyIndexGetReq.current.call();
    }

    useEffect(() => {
        if (myProfile && freeze.current === false) {
            getCompanyIdx();
        }
    }, [myProfile]);

    return { userCompanyIndex };
}

export default useGetCompanyIndex;
