import { atom } from 'recoil';

interface MemberRegisterModel {
    profileImg: File | null;
    name: string;
    birth: string;
    registrationNum: string;
    phone: string;
    id: string;
    email: string;
    address: string;
    addressDetail: string;
    addressPostCode: string;
    cardMonth: string;
    cardYear: string;
    cardNum: Array<string>;
    carNum: string;
    point: string;
    phoneCheck: CheckStatus;

    cardType: 1 | 2;
    chargeCardCompanyIdx: number;
    chargeCardCompanyName?: string;
    chargeCardNumber: string;
    memberCardIdx: number;
    memberCardName?: string;
    chargeCardPostCode: string;
    chargeCardAddress: string;
    chargeCardAddressDetail: string;
    cardStatus?: string;

    creditCardCompanyCode: string;
    creditCardCompanyName: string;
    creditCardYear: string;
    creditCardMonth: string;
    creditCardNumber: string;
    creditCardBillingKey: string;
    creditCardType: number;

    businessRegistrationNumberEdit: Array<string>;
    businessRegistrationNumber?: string;
}

const $memberRegister = atom<MemberRegisterModel>({
    key: '@member/general-member/member-register/form',
    default: {
        profileImg: null,
        name: '',
        birth: '',
        registrationNum: '',
        phone: '',
        phoneCheck: 'N',
        id: '',
        email: '',
        address: '',
        addressDetail: '',
        addressPostCode: '',
        cardMonth: '',
        cardYear: '',
        cardNum: ['', '', '', ''],
        carNum: '',
        point: '',
        cardType: 1,
        chargeCardCompanyIdx: 0,
        chargeCardCompanyName: '',
        chargeCardNumber: '',
        memberCardIdx: 0,
        memberCardName: '',
        chargeCardPostCode: '',
        chargeCardAddress: '',
        chargeCardAddressDetail: '',
        creditCardCompanyCode: '',
        creditCardCompanyName: '',
        creditCardYear: '',
        creditCardMonth: '',
        creditCardNumber: '',
        creditCardBillingKey: '',
        creditCardType: 1,
        businessRegistrationNumberEdit: ['', '', ''],
        businessRegistrationNumber: '',
    },
});

export default $memberRegister;
