import { atom } from 'recoil';
import { GetCompanyResponseItemModel } from '../../../query/common/get-company';

export const defaultValue: GetCompanyResponseItemModel = {
    companyIdx: 0,
    companyName: '',
};

const $selectedCompany = (domain: string) => {
    return atom<GetCompanyResponseItemModel>({
        key: `@${domain}/selected-company`,
        default: defaultValue,
    });
};

export default $selectedCompany;
