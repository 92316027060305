import { AxiosError } from 'axios';
import { useRecoilValue } from 'recoil';
import { useQueryClient } from 'react-query';

import DashboardAuthModel from '../service/model/auth/dashboard-auth-model';

import $isLogin from '../recoil/atom/login/is-login';
import useGetSetPermissionList, {
    LevelNumbering,
} from '../query/operation/general/set-permission/get-set-permission-list';
import onErrorAccessTokenRefresh from '../shared/on-error-access-token-refresh';
import AuthManager from '../shared/auth-manager';
import InfraAuthModel from '../service/model/auth/infra-auth-model';
import BillingAuthModel from '../service/model/auth/billing-auth-model';
import MemberAuthModel from '../service/model/auth/member-auth-model';
import OperationAuthModel from '../service/model/auth/operation-auth-model';
import usePopupAlert from './use-popup-alert';
import { getAuthErrorMessage } from '../shared/function';

interface GetAuthMap {
    대시보드: DashboardAuthModel;
    인프라: InfraAuthModel;
    빌링: BillingAuthModel;
    회원: MemberAuthModel;
    운용: OperationAuthModel;
}

function useAuth(domain?: string) {
    const queryClient = useQueryClient();

    const { setAlertMsg } = usePopupAlert(domain ?? '');

    const isLogin = useRecoilValue($isLogin);

    const am = new AuthManager();

    const { data } = useGetSetPermissionList(
        {
            pageSize: 0,
            currentPage: 0,
        },
        {
            enabled: isLogin,
            retry: false,
            onError: (err) => {
                onErrorAccessTokenRefresh(err as Error | AxiosError, () =>
                    queryClient.refetchQueries('@operation/general/set-permission/get-set-permission-list')
                );
            },
            refetchOnWindowFocus: true,
        }
    );

    const isLoad = data !== undefined;

    function getAuth<K extends keyof GetAuthMap>(menuName1: K) {
        let auths: any[] = [];

        if (isLoad) {
            auths = data!.list.filter((item) => item.menuName1 === menuName1);
        }

        function curry<T extends keyof GetAuthMap[K]>(menuName2: T) {
            const authsByMenuName2 = auths.filter((item) => item.menuName2 === menuName2);

            function curry<X extends keyof GetAuthMap[K][T]>(menuName3: X) {
                const authsByMenuName3 = authsByMenuName2.filter((item) => item.menuName3 === menuName3);

                function curry<Z extends keyof GetAuthMap[K][T][X]>(name: Z) {
                    const level = am.getAdminLevel() as LevelNumbering;

                    const auth = authsByMenuName3.find((item) => item.name === name);

                    if (auth) {
                        return auth[`level${level}`] === 1;
                    }
                    return false;
                }

                return curry;
            }

            return curry;
        }

        return curry;
    }

    function getFindEnabledConnectPage(mn1: string, mn2: string, mn3: string, mn4: string) {
        const level = am.getAdminLevel() as LevelNumbering;
        const auth = data?.list ? data.list : [];

        const findIndex = auth.findIndex(
            (fi) => fi.menuName1 === mn1 && fi.menuName2 === mn2 && fi.menuName3 === mn3 && fi.name === mn4
        );

        // eslint-disable-next-line no-plusplus
        for (let a = findIndex; a < auth.length; a++) {
            if (a === findIndex) break;

            if (auth[a][`level${level}`] === 1) {
                break;
            }

            if (a === auth.length - 1) a = -1;
        }
    }

    function alert(name: string) {
        if (domain) {
            setAlertMsg('error', getAuthErrorMessage(name));
        }
    }

    return {
        isLoad,
        getAuth,
        alert,
    };
}

export default useAuth;
