import C from 'crypto-js';
import DecryptDataModel from '../model/data-model/decrypt-data-model';
import DecryptData from './decrypt-data';

// 암호화 함수
export const enc = (__e: string | number): string => {
    // number 데이터의 경우 to String 필요
    const ePrev = __e.toString();

    // // 데이터 암호화 시
    // // 암호화할 데이터 + 암호화 시점 + 암호화된 URL 합하여 암호화
    // const e = `${ePrev}${process.env.REACT_APP_SPLITTER}${new Date().valueOf()}${process.env.REACT_APP_SPLITTER}${
    //     window.location.href
    // }`;

    // // 이중 암호화
    // return C.AES.encrypt(
    //     C.AES.encrypt(e, process.env.REACT_APP_CK ?? '').toString(),
    //     process.env.REACT_APP_SK ?? ''
    // ).toString();

    // 2024.04.18 basagee@gmail.com
    // 이중암호화 오류 문제로 임시 제거
    // 데이터 암호화 시
    return C.AES.encrypt(ePrev, process.env.REACT_APP_CK ?? '').toString();
};

// 복호화 함수
export const dec = (e: string): DecryptDataModel => {
    // // 이중 암호호된 데이터 복호화
    // const _ = C.AES.decrypt(
    //     C.AES.decrypt(e, process.env.REACT_APP_SK ?? '').toString(C.enc.Utf8),
    //     process.env.REACT_APP_CK ?? ''
    // )
    //     .toString(C.enc.Utf8)
    //     .split(process.env.REACT_APP_SPLITTER ?? '');

    // // 구분 기호 기준
    // // 0번째 데이터 : 암호화된 실질적 데이터
    // // 1번째 데이터 : 암호화 시점
    // // 2번째 데이터 : 암호화된 URL

    // // 실질적 데이터가 number인 경우 parseInt하여 전달
    // const v = Number.isNaN(parseInt(_[0], 10)) ? _[0] : parseInt(_[0], 10);
    // const d = parseInt(_[1], 10);
    // const h = _[2];

    // // 직접접근 불가능한 데이터로 반환
    // return new DecryptData({ value: _.toString(), date: d, href: h });
    const decString = C.AES.decrypt(e, process.env.REACT_APP_SK ?? '').toString(C.enc.Utf8);
    return new DecryptData({ value: decString, date: null, href: null });
};

// 로컬스토리지 key 반환 함수
// sha256의 경우 단방향 암호화이기 때문에
// 복호화는 불가능하지만 값이 일정하므로 key값 암호화 방식으로 채택
export const encSha256 = (e: string): string => {
    return C.SHA256(e).toString();
};
