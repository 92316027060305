/* eslint-disable class-methods-use-this */
import axios from "axios";
import StorageManager from "./storage-manager";
import { LoginResponseModel } from "../service/model/auth/login-model";

const sm = new StorageManager();

type Key = "accessToken" | "refreshToken";

type AdminKey = "id" | "name" | "level" | "profilePath";

const util = {
    getData: <T>(key: string): T | null => {
        if (sm.get("auth-data").getValue() === null) return null;

        return sm.get("auth-data").getObject(key) as T;
    },

    setData: (key: Key, value: string) => {
        const sm = new StorageManager();

        const origin = sm.get("auth-data").getObject() as LoginResponseModel;

        origin[key] = value;

        new AuthManager().login(origin);
    },

    setAdminInfo: (key: AdminKey, value: string | number) => {
        const sm = new StorageManager();

        const origin: any = sm
            .get("auth-data")
            .getObject() as LoginResponseModel;

        origin.admin[key] = value;

        new AuthManager().login(origin);
    },
};

class AuthManager {
    login(data: LoginResponseModel) {
        sm.set("auth-data", data);
    }

    logout() {
        try {
            axios({
                url: `${process.env.REACT_APP_API_URL}/auth/logout`,
                method: "DELETE",
                data: {
                    refreshToken: this.getRefreshToken(),
                },
            });
        } catch {
            //
        }

        sm.remove("auth-data");
    }

    isLogin() {
        return sm.get("auth-data").getValue() !== null;
    }

    isGuest() {
        return sm.get("auth-data").getValue() === null;
    }

    getAccessToken() {
        return util.getData<string>("accessToken");
    }

    getRefreshToken() {
        return util.getData<string>("refreshToken");
    }

    getAuthData() {
        return sm.get("auth-data").getObject() as LoginResponseModel;
    }

    setAccessToken(accessToken: string) {
        return util.setData("accessToken", accessToken);
    }

    setRefreshToken(refreshToken: string) {
        return util.setData("refreshToken", refreshToken);
    }

    setAdminInfoName(name: string) {
        return util.setAdminInfo("name", name);
    }

    setAdminInfoData(
        id: string,
        name: string,
        level: number,
        profilePath: string
    ) {
        util.setAdminInfo("id", id);
        util.setAdminInfo("name", name);
        util.setAdminInfo("level", level);
        util.setAdminInfo("profilePath", profilePath);
    }

    getAdminLevel() {
        const authData = sm.get("auth-data").getObject() as LoginResponseModel;
        return authData.admin.level;
    }
}

export default AuthManager;
