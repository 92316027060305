import axios, { CancelTokenSource, Method } from "axios";
import AuthManager from "../shared/auth-manager";
import { errorParser } from "../shared/function";
import onErrorAccessTokenRefresh from "../shared/on-error-access-token-refresh";
import UserManager from "../shared/user-manager";
import Service, { CallbackModel, ServiceGetConfigModel } from "./service";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

class Get<T> implements Service<T> {
    url = "";

    method: Method = "GET";

    config: ServiceGetConfigModel<T> = {};

    cancelToken: CancelTokenSource | null = null;

    loading = false;

    callback: CallbackModel | null = null;

    constructor(url: string, callback: CallbackModel) {
        this.url = url;
        this.callback = callback;
    }

    async call() {
        if (this.config.loadingBlock && this.loading) {
            return;
        }

        if (this.config.autoCancel) {
            this.cancel();
        }

        if (
            this.config.loginCheck &&
            (this.config.isUserRequest !== undefined
                ? new UserManager().isGuest()
                : new AuthManager().isGuest()) &&
            this.callback?.error
        ) {
            this.callback?.error({
                msg: "로그인 후 이용 가능합니다.",
                isAxiosError: false,
                isCancel: false,
                code: 401,
            });

            return;
        }

        this.cancelToken = axios.CancelToken.source();

        this.loading = true;

        await axios({
            url: this.url,
            method: this.method,
            params: this.config.params,
            cancelToken: this.cancelToken?.token,
            headers: this.getAccessHeader(),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    const data = res.data.data ?? res.data;
                    this.callback?.success(data);
                } else {
                    throw res.data;
                }
            })
            .catch((err) => {
                const errorData = errorParser(err);

                if (errorData.code === 401) {
                    onErrorAccessTokenRefresh(err, async () => {
                        await this.call();
                    });
                } else if (this.callback?.error) {
                    this.callback?.error(errorData);
                }
            })
            .finally(() => {
                if (this.callback?.finally) {
                    this.callback.finally();
                }
            });

        this.loading = false;
    }

    set(config: ServiceGetConfigModel<T>) {
        this.config = config;
        return this;
    }

    cancel() {
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }
    }

    getAccessHeader() {
        return this.config.loginCheck
            ? {
                  Authorization: `Bearer ${
                      this.config.isUserRequest
                          ? new UserManager().getAccessToken()
                          : new AuthManager().getAccessToken() ?? ""
                  }`,
              }
            : undefined;
    }
}

export default Get;
