import { useRecoilValue } from 'recoil';
import $media from '../../recoil/atom/mediaAtom';
import '../../style/components/layout/header.scss';
import '../../style/components/layout/mo_header.scss';

import MoHeader from '../layout/mo_header';

function Header() {
    const media = useRecoilValue($media);

    if (media === 'P')
        return (
            <header id="pc_header">
                <div className="header_contents">
                    <div className="top">
                        <div className="dashboard_title ff_m ">EV충전인프라 운영시스템</div>
                    </div>
                </div>
            </header>
        );

    return <MoHeader />;
}

export default Header;
