import { atom } from 'recoil';
import { GetCarModelDetailResponseModel } from '../../../../../query/operation/general/car-model/get-car-model-detail';

export const defaultValue: GetCarModelDetailResponseModel['car'] = {
    idx: 0,
    imgPath: '',
    url: '',
    makerIdx: 0,
    name: '',
    carId: '',
    fastType1: 0,
    fastType2: 0,
    fastType3: 0,
    fastType4: 0,
    slowType: 0,
};

const $carModelDetail = atom<GetCarModelDetailResponseModel['car']>({
    key: '@operation/general/car-model/car-model-detail',
    default: defaultValue,
});

export default $carModelDetail;
