import { atom } from 'recoil';

interface MyInfoModifyModel {
    name: string;
    adminName: string;
    id: string;
    postcode: string;
    address: string;
    addressDetail: string;
    phone: string;
    adminNamePass: boolean;
    idPass: boolean;
    profileImg: File | null;
    delProfile: 1 | 0; // 1: 프로필 삭제 , 0: 프로필 유지
}

const $myInfoModify = atom<MyInfoModifyModel>({
    key: '@member/my-info-modify',
    default: {
        name: '',
        adminName: '',
        id: '',
        postcode: '',
        address: '',
        addressDetail: '',
        phone: '',
        adminNamePass: true,
        idPass: true,
        profileImg: null,
        delProfile: 0,
    },
});

export default $myInfoModify;
