import {
    Dispatch,
    Fragment,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import { useSetRecoilState } from "recoil";

import { Link, useNavigate } from "react-router-dom";
import {
    isPathName,
    isPathName2,
    isPathName3,
    onErrorImg,
} from "../../shared/function";

import "../../style/components/layout/mo_header.scss";
import hamburger from "../../static/img/icons/hamburger.png";
import close from "../../static/img/icons/close.png";
import arrowDowm from "../../static/img/icons/menu_arrow.png";
import arrowUp from "../../static/img/icons/menu_arrow_active.png";
import listStyle from "../../static/img/icons/list_style.png";
import defaultUser from "../../static/img/icons/default_user.png";
import user from "../../static/img/icons/user.png";
import bag from "../../static/img/icons/bag.png";
import power from "../../static/img/icons/power.png";
import { PATH } from "../../shared/constant";
import AuthManager from "../../shared/auth-manager";
import $isLogin from "../../recoil/atom/login/is-login";
import useGetCompanyIndex from "../../hook/use-get-company-index";
import If from "./if";
import useAuth from "../../hook/use-auth";

export const MoMenuData = [
    {
        menu: "인프라",
        link: "/csms/infra",
        sub: [
            { submenu: "대시보드", link: "/csms/infra" },
            { submenu: "충전소 리스트", link: "/csms/infra/station_list" },
            {
                submenu: "충전소 등록",
                link: "/csms/infra/station_registration",
            },
            { submenu: "충전기 리스트", link: "/csms/infra/charger_list" },
        ],
    },
    {
        menu: "빌링",
        link: "/csms/billing",
        sub: [
            { submenu: "대시보드", link: "/csms/billing" },
            {
                submenu: "결제내역",
                link: "/csms/payment",
                last: [
                    {
                        lastmenu: "결제 정보",
                        link: "/csms/billing/payment/payment_info",
                    },
                    {
                        lastmenu: "검증결제 정보",
                        link: "/csms/billing/payment/verification_payment_info",
                    },
                    {
                        lastmenu: "결제취소 정보",
                        link: "/csms/billing/payment/payment_cancel_info",
                    },
                ],
            },
            // {
            //     submenu: "정산",
            //     link: "/csms/accounting",
            //     last: [
            //         {
            //             lastmenu: "정산 진행",
            //             link: "/csms/billing/accounting/processing",
            //         },
            //         {
            //             lastmenu: "정산 보고서",
            //             link: "/csms/billing/accounting/reports",
            //         },
            //         {
            //             lastmenu: "정산 설정",
            //             link: "/csms/billing/accounting/settings",
            //         },
            //     ],
            // },
            { submenu: "포인트", link: "/csms/billing/point" },
            { submenu: "충전요금 설정", link: "/csms/billing/rate" },
        ],
    },
    {
        menu: "회원",
        link: "/csms/member",
        sub: [
            { submenu: "대시보드", link: "/csms/member" },
            {
                submenu: "일반회원",
                link: "/csms/general",
                last: [
                    {
                        lastmenu: "회원 리스트",
                        link: "/csms/member/general_member/member_list",
                    },
                    {
                        lastmenu: "회원 등록",
                        link: "/csms/member/general_member/member_reg",
                    },
                    {
                        lastmenu: "접속 정보",
                        link: "/csms/member/general_member/access_info",
                    },
                    {
                        lastmenu: "회원카드 발급",
                        link: "/mscmmember/general_member/card_issuer",
                    },
                    {
                        lastmenu: "약관",
                        link: "/csms/member/general_member/terms",
                    },
                    {
                        lastmenu: "약관 등록",
                        link: "/csms/member/general_member/terms_reg",
                    },
                ],
            },
            {
                submenu: "사업자",
                link: "/csms/member/business",
                last: [
                    {
                        lastmenu: "사업자 리스트",
                        link: "/csms/member/business/business_list",
                    },
                    {
                        lastmenu: "사업자 정보",
                        link: "/csms/member/business/business_info",
                        showOnThisLink: true,
                        disabled: true,
                    },
                    {
                        lastmenu: "사업자 등록",
                        link: "/csms/member/business/business_reg",
                    },
                    {
                        lastmenu: "사업자 빌링",
                        link: "/csms/member/business/business_billing",
                    },
                ],
            },
            {
                submenu: "관제요원",
                link: "/csms/member/manager",
                last: [
                    {
                        lastmenu: "관제요원 리스트",
                        link: "/csms/member/manager/manager_list",
                    },
                    {
                        lastmenu: "접속 정보",
                        link: "/csms/member/manager/manager_access_info",
                    },
                    {
                        lastmenu: "로그",
                        link: "/csms/member/manager/manager_log",
                    },
                ],
            },
        ],
    },
    {
        menu: "운용",
        link: "/csms/operation",
        sub: [
            { submenu: "대시보드", link: "/csms/operation" },
            { submenu: "전력", link: "/csms/operation/power" },
            { submenu: "통신", link: "/csms/operation/communication" },
            {
                submenu: "고장/점검",
                link: "/csms/failure",
                last: [
                    {
                        lastmenu: "고장 정보",
                        link: "/csms/operation/failure/failure_info",
                    },
                    {
                        lastmenu: "점검 정보",
                        link: "/csms/operation/failure/check_info",
                    },
                    {
                        lastmenu: "장애 신고",
                        link: "/csms/operation/failure/failure_declare",
                    },
                    {
                        lastmenu: "작업보고서",
                        link: "/csms/operation/failure/work_report",
                    },
                ],
            },
            { submenu: "펌웨어", link: "/csms/operation/firmware" },
            {
                submenu: "일반",
                link: "/csms/general",
                last: [
                    {
                        lastmenu: "권한 설정",
                        link: "/csms/operation/general/set_permission",
                    },
                    {
                        lastmenu: "보고서",
                        link: "/csms/operation/general/report",
                    },
                    {
                        lastmenu: "제조사 정보",
                        link: "/csms/operation/general/maker",
                    },
                    {
                        lastmenu: "전기차 모델정보",
                        link: "/csms/operation/general/car_model",
                    },
                    {
                        lastmenu: "충전기 모델정보",
                        link: "/csms/operation/general/charger_model",
                    },
                    {
                        lastmenu: "회원카드 설정",
                        link: "/csms/operation/general/set_member_card",
                    },
                    {
                        lastmenu: "충전 단가",
                        link: "/csms/operation/general/charge_unit_price",
                    },
                ],
            },
            {
                submenu: "고객센터",
                link: "/csms/service_center",
                last: [
                    {
                        lastmenu: "회원 문의",
                        link: "/csms/operation/service_center/member_inquiry",
                    },
                    {
                        lastmenu: "상담원 명부",
                        link: "/csms/operation/service_center/consultant_list",
                    },
                    {
                        lastmenu: "상담 리스트",
                        link: "/csms/operation/service_center/consultation_list",
                    },
                    {
                        lastmenu: "상담등록",
                        link: "/csms/operation/service_center/consultation_reg",
                    },
                ],
            },
        ],
    },
    // {
    //   menu: "마이페이지",
    //   sub: [
    //     { submenu: "내 정보", link: "" },
    //     { submenu: "사업자 정보", link: "" },
    //   ],
    // },
];

function MoHeader() {
    const navigate = useNavigate();
    const am = new AuthManager();
    const authData = am.getAuthData();
    const { profilePath } = authData.admin;
    const { getAuth } = useAuth();
    const auth = getAuth("회원")("사업자")("사업자 리스트")("사업자 상세");
    const profileRef = useRef<HTMLDivElement>(null);
    const [side, setSide] = useState(false);
    const [active, setActive] = useState(0); // depth:2 submenu
    const [active2, setActive2] = useState(0); // depth:3 lastmenu
    const [myProfile, setMyProfile] = useState(false);

    const setIsLogin = useSetRecoilState($isLogin);

    const { userCompanyIndex } = useGetCompanyIndex(myProfile);

    function onLogout() {
        new AuthManager().logout();
        setIsLogin(false);
        navigate(PATH.LOGIN);
    }

    function onClickContainsState(
        state: number,
        setState: Dispatch<SetStateAction<number>>,
        e: number
    ) {
        setState(e);
        if (String(state).includes(String(e))) {
            setState(0);
        }
    }

    function onCheckVaildArea(e: MouseEvent) {
        if (
            profileRef.current &&
            !profileRef.current.contains(e.target as Node)
        ) {
            setMyProfile(false);
        }
    }

    useEffect(() => {
        window.addEventListener("click", onCheckVaildArea);
        return () => {
            window.addEventListener("click", onCheckVaildArea);
        };
    }, [myProfile]);

    return (
        <div id="mo_header">
            <div className="header_content">
                <div className="menu">
                    <img
                        src={hamburger}
                        alt="hamburger"
                        onClick={() => setSide(!side)}
                    />
                </div>
                <div className="logo">
                    <Link to="/">
                        <span>EV충전인프라 운영시스템</span>
                    </Link>
                </div>
                <div className="profile" ref={profileRef}>
                    <img
                        src={profilePath || defaultUser}
                        alt="mo_profile"
                        onClick={() => setMyProfile(!myProfile)}
                        onError={(e) => onErrorImg(e, defaultUser)}
                    />
                    {myProfile === true && (
                        <div className="drop_down">
                            <div className="my_info">
                                <Link to={PATH.MY_INFO}>
                                    <img src={user} alt="내 정보" />
                                    <span
                                        className={`${
                                            window.location.pathname ===
                                            PATH.MY_INFO
                                                ? "color_skyBlue"
                                                : ""
                                        }`}
                                    >
                                        내 정보
                                    </span>
                                </Link>
                            </div>

                            <If
                                predicate={!!userCompanyIndex}
                                right={
                                    <div className="business_info">
                                        <Link
                                            to={`${PATH.BUSINESS_INFO}?idx=${userCompanyIndex}`}
                                        >
                                            <img src={bag} alt="사업자 정보" />
                                            <span
                                                className={`${
                                                    `${window.location.pathname}${window.location.search}` ===
                                                    `${PATH.BUSINESS_INFO}?idx=${userCompanyIndex}`
                                                        ? "color_skyBlue"
                                                        : ""
                                                }`}
                                            >
                                                사업자 정보
                                            </span>
                                        </Link>
                                    </div>
                                }
                            />

                            <div className="logout">
                                <button
                                    type="button"
                                    className="logout"
                                    onClick={onLogout}
                                >
                                    <img src={power} alt="LOGOUT" />{" "}
                                    <span>LOGOUT</span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                <div id="mo_sidebar" className={side ? "active" : ""}>
                    <div className="close">
                        <img
                            src={close}
                            alt="hamburger"
                            onClick={() => setSide(!side)}
                        />
                    </div>

                    <div className="menu_list">
                        <div className="menu_item">
                            <div className="menu_title">
                                <Link to="/csms">
                                    <span
                                        className={`ff_m ${
                                            isPathName("/") ? "active" : ""
                                        }`}
                                    >
                                        DASHBOARD
                                    </span>
                                </Link>
                            </div>
                        </div>

                        {MoMenuData.map((item, index) => {
                            return (
                                <div className="menu_item" key={item.link}>
                                    <div
                                        className="menu_title"
                                        // onClick={() => setActive(index + 1)}
                                        onClick={() =>
                                            onClickContainsState(
                                                active,
                                                setActive,
                                                index + 1
                                            )
                                        }
                                    >
                                        <span
                                            className={`ff_m ${
                                                active === index + 1 ? "on" : ""
                                            } ${
                                                isPathName2(item.link)
                                                    ? "active"
                                                    : ""
                                            }`}
                                        >
                                            {item.menu}
                                        </span>
                                        <img
                                            src={
                                                active === index + 1
                                                    ? arrowUp
                                                    : arrowDowm
                                            }
                                            alt="arrow"
                                        />
                                    </div>

                                    {/* depth 2 */}
                                    {active === index + 1 && (
                                        <div className="mo_submenu">
                                            {item.sub.map((item2, index2) => {
                                                const path = item2.last
                                                    ? item.link + item2.link
                                                    : item2.link;
                                                const isPath: boolean =
                                                    item2.last
                                                        ? isPathName3(path)
                                                        : isPathName(path);
                                                return (
                                                    <Fragment key={item2.link}>
                                                        <Link
                                                            to={item2.link}
                                                            onClick={(e) => {
                                                                onClickContainsState(
                                                                    active2,
                                                                    setActive2,
                                                                    index2 + 1
                                                                );
                                                                if (
                                                                    item2.last
                                                                ) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        >
                                                            <span
                                                                className={
                                                                    isPath
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                            >
                                                                {item2.submenu}
                                                            </span>
                                                            {item2.last && (
                                                                <img
                                                                    src={
                                                                        active2 ===
                                                                        index2 +
                                                                            1
                                                                            ? arrowUp
                                                                            : arrowDowm
                                                                    }
                                                                    alt="arrow"
                                                                />
                                                            )}
                                                        </Link>
                                                        {item2.last &&
                                                            active2 ===
                                                                index2 + 1 && (
                                                                <div className="mo_lastmenu">
                                                                    {item2.last.map(
                                                                        (
                                                                            item3
                                                                        ) => {
                                                                            const isActive =
                                                                                isPathName(
                                                                                    item3.link ??
                                                                                        ""
                                                                                );

                                                                            if (
                                                                                isActive ===
                                                                                    false &&
                                                                                item3.showOnThisLink ===
                                                                                    true
                                                                            ) {
                                                                                return (
                                                                                    <Fragment
                                                                                        key={
                                                                                            item2.link
                                                                                        }
                                                                                    />
                                                                                );
                                                                            }

                                                                            return (
                                                                                <Fragment
                                                                                    key={
                                                                                        item3.link
                                                                                    }
                                                                                >
                                                                                    <Link
                                                                                        to={
                                                                                            item3.link
                                                                                        }
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            if (
                                                                                                item3.disabled
                                                                                            ) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                listStyle
                                                                                            }
                                                                                            alt="listStyle"
                                                                                        />
                                                                                        <span
                                                                                            className={
                                                                                                isPathName(
                                                                                                    item3.link
                                                                                                )
                                                                                                    ? "active"
                                                                                                    : ""
                                                                                            }
                                                                                        >
                                                                                            {" "}
                                                                                            {
                                                                                                item3.lastmenu
                                                                                            }
                                                                                        </span>
                                                                                    </Link>
                                                                                </Fragment>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            )}
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MoHeader;
