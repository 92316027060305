import { dec, enc, encSha256 } from './passport';

import DecryptDataModel from '../model/data-model/decrypt-data-model';

import DecryptData from './decrypt-data';

// 비어있는 데이터
// null로 처리
const emptyDecryptData = new DecryptData({
    value: null,
    date: null,
    href: null,
});

interface ObjectModel {
    [key: string]: any;
}

/* eslint-disable class-methods-use-this */
export default class StorageManager {
    // 로컬 스토리지 데이터 암호화로 저장
    set(_key: string, __value: string | number | ObjectModel | Array<any>): void {
        const valuePrev = typeof __value === 'object' ? JSON.stringify(__value) : __value;

        const key = encSha256(_key);
        const value = enc(valuePrev);
        // basagee debug
        if (_key === 'auth-data') localStorage.setItem('TTTTTT', valuePrev as string);

        localStorage.setItem(key, value);
    }

    // 암호화된 로컬 스토리지 데이터 가져오기
    get(_key: string): DecryptDataModel {
        const key = encSha256(_key);

        var getItem = localStorage.getItem(key);
        // 값이 존재하는 경우에만 반환
        if (getItem) {
            // 암호화된 데이터 복호화
            const d = dec(getItem);
            return d;
        }
        // 값이 존재하지 않은 경우 비어있는 데이터 반환
        return emptyDecryptData;
    }

    // 암호회된 로컬 스토리지 데이터 삭제
    remove(_key: string): void {
        const key = encSha256(_key);

        localStorage.removeItem(key);
    }

    // 로컬 스토리지 비우기
    clean(): void {
        localStorage.clear();
    }
}
