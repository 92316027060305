import { useEffect } from 'react';

const useComponentWillUnmount = (fn: () => void) => {
    useEffect(() => {
        return () => {
            fn();
        };
    }, []);
};

export default useComponentWillUnmount;
