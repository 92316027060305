import { atom } from 'recoil';

type MediaType = 'M' | 'P';

const $media = atom<MediaType>({
    key: '@window/media',
    default: window.innerWidth <= 768 ? 'M' : 'P',
});

export default $media;
