import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Select, { optionsData } from "../components/select/select";
import "../style/pages/scenario.scss";

interface ButtonModel
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const Component = {
    Button(props: ButtonModel) {
        const { onClick, children } = props;

        return (
            <button
                {...props}
                type="button"
                className={`btn ${
                    children?.toString().includes("Update Status")
                        ? "lightGreen"
                        : "skyBlue"
                }`}
                onClick={onClick}
            >
                {children}
            </button>
        );
    },
};

const util = {
    API_URL: process.env.REACT_APP_OCPP_URL,
    createRequestLog: (message: string): LogModel => {
        return {
            time: new Date(),
            statusType: "open",
            message: `🌏 ${message}`,
        };
    },
    createOpenLog: (message: string): LogModel => {
        return {
            time: new Date(),
            statusType: "open",
            message: `🚪 ${message}`,
        };
    },
    createSuccessLog: (message: any, name?: string): LogModel => {
        let pushMessage: any = message;

        if (typeof message === "object") {
            pushMessage = JSON.stringify(pushMessage);
        }

        return {
            time: new Date(),
            statusType: "success",
            message: `🙌 ${name ?? ""} : ${pushMessage}`,
        };
    },
    createErrorLog: (message: string): LogModel => {
        return {
            time: new Date(),
            statusType: "error",
            message: `❌ ${message}`,
        };
    },
};

type StatusType = "open" | "success" | "error";

interface LogModel {
    time: Date;
    statusType: StatusType;
    message: string;
}

interface ScenariosModel {
    code: string;
    param: string;
}

interface ScenModel {
    id: string;
    name: string;
    newLineFlag: boolean;
    scenarioes: Array<ScenariosModel>;
}

interface ParamsModel {
    key: string;
    value: string;
}

function Scenario() {
    const ulRef = useRef<HTMLUListElement | null>(null);

    const [scens, setScens] = useState<Array<ScenModel>>([]);

    const [logs, setLogs] = useState<Array<LogModel>>([]);

    const [selected, setSelected] = useState<string>("");

    const [options, setOptions] = useState<Array<optionsData>>([]);

    const [params, setParams] = useState<Array<ParamsModel>>([]);

    const [currentSelectButton, setCurrentSelectButton] = useState("선택");

    function pushLogs(log: LogModel) {
        setLogs((_) => _.concat(log));
    }

    function onClickScenCaller(e: React.MouseEvent<HTMLButtonElement>) {
        const { id, dataset } = e.currentTarget;

        const { name } = dataset;

        const options: Array<optionsData> = scens
            .filter((i) => i.id === id)[0]
            .scenarioes.map((i) => ({
                id: i.param,
                optionValue: i.code,
            }));

        setOptions(options);
        setCurrentSelectButton(name ?? "");
    }

    function getScens() {
        const evt = new EventSource(`${util.API_URL}/Refresh?`);

        evt.onmessage = (res) => {
            const stringData = res.data as string;

            const jsonData: { data: Array<ScenModel> } = JSON.parse(stringData);

            setScens(jsonData.data);

            evt.close();
        };
    }

    function scrollToBottom() {
        if (ulRef.current) {
            const { current } = ulRef;

            current.scrollTo({ top: current.scrollHeight, behavior: "smooth" });
        }
    }

    function onChangeSelect(e: optionsData) {
        setSelected(e.optionValue);
        setParams(
            e.id
                ? e.id!.split("&").map((i) => ({
                      key: i.split("=")[0],
                      value: i.split("=")[1],
                  }))
                : []
        );
    }

    function onClickSend() {
        if (canSend) {
            const scen = selected;
            const name = currentSelectButton;
            const { id } = scens.filter((i) => i.name === name)[0];
            const parameter = params.length
                ? `?${params
                      .map((i) => {
                          return `${i.key}=${i.value}`;
                      })
                      .join("&")}`
                : "";

            const evt = new EventSource(`${util.API_URL}/${id}${parameter}`);

            pushLogs(
                util.createRequestLog(`[${scen}] : ${name}${parameter} Request`)
            );

            evt.onopen = () => {
                pushLogs(
                    util.createOpenLog(`[${scen}] : ${name}${parameter} Opened`)
                );
            };
            evt.onmessage = (res) => {
                pushLogs(
                    util.createSuccessLog(
                        res.data,
                        `[${scen}] : ${name}${parameter}`
                    )
                );
                evt.close();
            };
            evt.onerror = () => {
                pushLogs(
                    util.createErrorLog(
                        `[${scen}] : ${name}${parameter} An Error Occurred`
                    )
                );
                evt.close();
            };
        }
    }

    useEffect(() => {
        scrollToBottom();
    }, [logs]);

    useEffect(() => {
        getScens();
    }, []);

    useEffect(() => {
        setSelected("");
        setParams([]);
    }, [currentSelectButton]);

    const canSend = selected !== "";

    return (
        <main id="scenario">
            <section>
                <header>
                    <div className="left">
                        <Select
                            options={options}
                            value={selected}
                            onChange={(e) => onChangeSelect(e)}
                            placeholder={currentSelectButton}
                        />

                        <div className="params">
                            Parameter :{" "}
                            {params.length === 0 && selected === "" && (
                                <p>Please Select Scenario</p>
                            )}
                            {params.length === 0 && selected !== "" && (
                                <>No parameter</>
                            )}
                            {params.map((i, index) => {
                                return (
                                    <React.Fragment key={i.key}>
                                        {i.key}={i.value}
                                        {index === params.length - 1 ? "" : "&"}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>

                    <button
                        type="button"
                        onClick={() => onClickSend()}
                        className={`btn ${canSend ? "skyBlue" : "gray"}`}
                    >
                        Send
                    </button>
                </header>
                <article>
                    <div className="top-button-wrap">
                        {scens.map(({ name, id, newLineFlag }) => {
                            return (
                                <React.Fragment key={id}>
                                    <Component.Button
                                        id={id}
                                        data-name={name}
                                        onClick={(e) => onClickScenCaller(e)}
                                    >
                                        {name}
                                    </Component.Button>
                                    {newLineFlag && (
                                        <div className="new-line" />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {/**
                     * Logs History Area
                     */}
                    <ul ref={ulRef}>
                        {logs.map(({ time, statusType, message }) => {
                            return (
                                <li key={time.valueOf()}>
                                    <p className="time">
                                        {moment(time).format(
                                            "YYYY-MM-DD hh:mm:ss"
                                        )}
                                    </p>
                                    <p className={statusType}>{message}</p>
                                </li>
                            );
                        })}
                    </ul>
                </article>
            </section>
        </main>
    );
}

export default Scenario;
