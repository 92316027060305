import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot, RecoilState } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './router';
import RecoilIndicator from './components/util/recoil-indicator';
import $myInfoModify from './recoil/atom/member/my-indo-modify';
import $selectedCompany from './recoil/atom/common/selected-company';
import $carModelDetail from './recoil/atom/operation/general/car-model/car-model-detail';
import $memberRegister from './recoil/atom/member/general-member/member-register/member-register';
import $isLogin from './recoil/atom/login/is-login';

const queryClient = new QueryClient();

const atoms = {
    $myInfoModify,
    dashboardSelectedCompany: $selectedCompany('dashboard'),
    InfraSelectedCompany: $selectedCompany('infra'),
    $carModelDetail,
    $memberRegister,
    $isLogin,
} as { [key: string]: RecoilState<unknown> };

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <RecoilRoot>
            <StrictMode>
                <Router />
            </StrictMode>
            <RecoilIndicator atoms={atoms} selectors={{}} />
        </RecoilRoot>
    </QueryClientProvider>,
    document.getElementById('root')
);
